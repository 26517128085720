import axios, { AxiosError } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const xApiKey = process.env.REACT_APP_X_API_KEY;

export enum METHOD {
  GET = 'get',
  POST = 'post',
}

/**
 * axiosInstance
 * instance of API
 */
export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': xApiKey,
  },
});

axiosInstance.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  (response: any) => response,
  (_error: AxiosError) => {
    if (_error) {
      return {
        data: undefined,
      };
    }
  },
);
