export enum FormStateEnums {
  ENCUESTA_CLIENTES = 'ENCUENTAS_CLIENTES',
  VALORACION_POSITIVA = 'VALORACION_POSITIVA',
  VALORACION_NEGATIVA = 'VALORACION_NEGATIVA',
  SUCCESS = 'SUCCESS',
  VALORACION = 'VALORACION',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  MAPS = 'MAPS',
}

export enum InputDisplayState {
  VALORACION = 'VALORACION',
  ENCUESTA = 'ENCUESTA',
}

export enum ValidationSchemasEnums {
  encuestasValidation = 'encuestasValidation',
  valoracionesValidation = 'valoracionValidation',
}

export enum ButtonTextEnums {
  siguiente = 'Siguiente',
  enviar = 'Enviar',
}
