import React from 'react';

const TapLogoColor: React.FC = () => (
  <svg width="91" height="31" viewBox="0 0 91 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M20.6766 20.7976C17.7902 20.7976 15.453 18.4273 15.453 15.5C15.453 12.5727 17.7902 10.2024 20.6766 10.2024L28.1352 10.2024C26.4749 7.7802 23.729 6.19998 20.5999 6.19998C15.5424 6.19998 11.4299 10.3578 11.4299 15.5C11.4299 20.6422 15.5424 24.8 20.5999 24.8C23.7162 24.8 26.4749 23.2198 28.1352 20.7976L20.6766 20.7976Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M47.3199 22.3887C45.2724 22.3887 43.9074 21.2405 43.9074 18.5998V11.1368H48.7986V8.26644H43.9074V2.5257H40.7224V18.7146C40.7224 23.3072 43.4524 25.4887 47.3199 25.4887C48.5711 25.4887 49.7086 25.259 50.6186 24.7998L49.2536 22.0442C48.6849 22.2739 48.0024 22.3887 47.3199 22.3887Z"
        fill="#002350"
      />
      <path
        d="M71.6625 28.4742V16.763C71.6625 15.0408 72.1175 13.5482 72.8 12.2853C73.5962 10.9075 74.62 9.87415 75.8712 9.07045C77.1225 8.26675 78.6012 7.9223 80.3075 7.9223C82.0137 7.9223 83.4925 8.26675 84.7437 9.07045C85.995 9.87415 87.1325 10.9075 87.815 12.2853C88.6112 13.663 88.9525 15.1556 88.9525 16.763C88.9525 18.4853 88.6112 19.9779 87.9287 21.2408C87.2462 22.6186 86.2225 23.6519 85.085 24.3408C83.8337 25.1445 82.4687 25.489 80.99 25.489C79.7387 25.489 78.6012 25.2593 77.5775 24.6853C76.5537 24.1112 75.6437 23.4223 74.9612 22.6186V28.4742H71.6625ZM80.3075 22.7334C81.3312 22.7334 82.355 22.5038 83.1512 21.9297C83.9475 21.3556 84.63 20.6667 85.1987 19.7482C85.6537 18.8297 85.8812 17.7964 85.8812 16.763C85.8812 15.6149 85.6537 14.5816 85.1987 13.663C84.7437 12.7445 84.0612 12.0556 83.1512 11.4816C82.355 10.9075 81.3312 10.6779 80.3075 10.6779C79.2837 10.6779 78.26 10.9075 77.4637 11.4816C76.6675 12.0556 75.985 12.7445 75.4162 13.663C74.9612 14.5816 74.7337 15.6149 74.7337 16.763C74.7337 17.9112 74.9612 18.9445 75.4162 19.7482C75.8712 20.6667 76.5537 21.3556 77.4637 21.9297C78.3737 22.389 79.2837 22.7334 80.3075 22.7334Z"
        fill="#002350"
      />
      <path
        d="M68.5913 24.8002H65.7475V21.3558H65.6338C64.61 23.6521 62.335 25.2595 59.2638 25.2595C55.055 25.2595 50.96 22.1595 50.96 16.5335C50.96 11.4817 54.6 7.80762 59.8325 7.80762C65.065 7.80762 68.5913 11.3669 68.5913 16.6484V24.8002ZM65.52 16.5335C65.52 13.2039 63.245 10.7928 59.8325 10.7928C56.42 10.7928 54.145 13.2039 54.145 16.5335C54.145 19.8632 56.42 22.2743 59.8325 22.2743C63.245 22.3891 65.52 19.8632 65.52 16.5335Z"
        fill="#002350"
      />
      <path
        d="M28.21 26.4074C28.21 27.5556 27.4137 28.4741 26.5037 28.4741H2.5025V2.52593H26.5037C27.4137 2.52593 28.21 3.44444 28.21 4.59259V10.2185H30.7125V4.59259C30.7125 2.06667 28.7787 0 26.5037 0H0V31H26.5037C28.7787 31 30.7125 28.9333 30.7125 26.4074V20.7815H28.21V26.4074Z"
        fill="#002350"
      />
      <g opacity="0.8">
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M18.7687 20.4236L25.276 23.4803C26.4135 22.7914 27.2741 21.934 28.0703 20.7859L20.7025 20.7681C20.02 20.7681 19.3375 20.6532 18.7687 20.4236Z"
              fill="#002350"
            />
          </g>
        </g>
      </g>
      <path
        d="M20.5886 12.2852H21.8399C23.5461 12.2852 25.0249 13.663 25.0249 15.5001C25.0249 17.3371 23.6599 18.7149 21.8399 18.7149H20.5886C18.8824 18.7149 17.4036 17.3371 17.4036 15.5001C17.4036 13.663 18.7686 12.2852 20.5886 12.2852Z"
        fill="#002350"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="11.4456"
        y1="15.4994"
        x2="29.784"
        y2="15.4994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14D2B9" />
        <stop offset="1" stopColor="#8C91FF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="91" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TapLogoColor;
