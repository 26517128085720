import { FormValuesInterface, TapServerResponse } from '../../../../types';
import { METHOD } from '../../config/axiosConfig';
import { BaseApi } from '../BaseApi';

export class LoyaltyApi extends BaseApi {
  /**
   * Get Company data from Loyalty Service
   *
   * GET /bussinesses/${company_id}
   * @param {String} company_id - ID from url params
   * @returns {Promise<TapServerResponse>} Response - {name: company_name}
   */
  async getCompany(company_id: string): Promise<TapServerResponse> {
    return this.publicRequest({
      method: METHOD.GET,
      url: `/businesses/${company_id}`,
    });
  }

  /**
   * Post user Review to Loyalty Service
   *
   * POST /reviews
   *
   * @body Values = {bussiness_id, client_id, product, score, message}
   * @returns {Promise<TapServerResponse>} Response - 201
   */
  async postUserReview(values: FormValuesInterface): Promise<TapServerResponse> {
    return this.publicRequest({
      method: METHOD.POST,
      url: '/reviews',
      data: values,
    });
  }
}

export default new LoyaltyApi();
