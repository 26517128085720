import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from '../config/axiosConfig';

export class BaseApi {
  /**
   * publicRequest
   * @param {AxiosRequestConfig} config
   */
  publicRequest = async (config: AxiosRequestConfig, wholeResponse = false) => {
    return axiosInstance
      .request(config)
      .then((response) => (wholeResponse ? response : response.data));
  };

  configAxiosInstance = {
    'Content-Type': 'application/json',
  };
}
