import { FormValuesInterface, TapServerResponse } from '../types';
import ApiService from './api/core';

export const loyaltyService = {
  get_company,
  post_user_review,
};

async function get_company(company_id: string): Promise<TapServerResponse | undefined> {
  try {
    const companyResponse = await ApiService.LoyaltyApi.getCompany(company_id);
    return companyResponse;
  } catch (err) {
    return err;
  }
}

async function post_user_review(values: FormValuesInterface): Promise<TapServerResponse> {
  try {
    const reviewResponse = await ApiService.LoyaltyApi.postUserReview(values);
    return reviewResponse;
  } catch (err) {
    return err;
  }
}
