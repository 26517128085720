import { Box, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
  AppGlobalState,
  FormBuilderInterface,
  FormStateEnums,
  FormValuesInterface,
} from '../../types';
import EncuestasCliente from './EncuestasCliente';
import ErrorScreen from './ErrorScreen';
import SuccessScreen from './SuccessScreen';
import ValoracionCliente from './ValoracionCliente';

interface FormikStepperProps {
  builder: FormBuilderInterface[];
  setFieldValue: (field: string, value: string | number, shouldValidate?: boolean) => void;
  values: FormValuesInterface;
  AppGlobalState: AppGlobalState;
}

const FormikStepper = ({ builder, setFieldValue, values, AppGlobalState }: FormikStepperProps) => {
  const useStyles = makeStyles((theme) => ({
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 50,
      height: 50,
      margin: 'auto',
      color: theme.palette.tertiary.main,
    },
  }));

  const classes = useStyles();
  switch (AppGlobalState.formState) {
    case FormStateEnums.MAPS:
      return <p>Google Maps</p>;
    case FormStateEnums.ENCUESTA_CLIENTES:
      return (
        <EncuestasCliente
          builder={builder}
          setFieldValue={setFieldValue}
          values={values}
          companyName={AppGlobalState.companyName}
        />
      );
    case FormStateEnums.VALORACION_POSITIVA:
    case FormStateEnums.VALORACION_NEGATIVA:
      return <ValoracionCliente score={values.score} formState={AppGlobalState.formState} />;
    case FormStateEnums.SUCCESS:
      return <SuccessScreen company_name={AppGlobalState.companyName} />;
    case FormStateEnums.ERROR:
      return <ErrorScreen />;
    default:
      return <CircularProgress className={classes.loading} />;
  }
};

export default FormikStepper;
