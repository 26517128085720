import { makeStyles } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { QualityForm } from '../../helpers/QualityForm';
import { appContainer } from '../../styles/globalStyles';
import { FormStateEnums } from '../../types/enums';
import StarComponent from '../generics/StarComponent';
import { LetterCount } from '../../types';

export interface ValoracionClienteProps {
  score: number;
  formState: FormStateEnums;
}

const ValoracionCliente = ({ score, formState }: ValoracionClienteProps) => {
  const useStyles = makeStyles((theme) => ({
    valoracionContainer: {
      ...appContainer,
    },
    mainText: {
      color: theme.palette.secondary.main,
      fontSize: 18,
      margin: 0,
      marginBottom: 18,
      marginTop: 28,
    },
    secondaryText: {
      fontSize: 16,
      marginBottom: 14,
      color: theme.palette.secondary.main,
      marginTop: -23,
      lineHeight: 1.4,
      fontWeight: 400,
      fontFamily: 'Open Sans',
    },
    textAreaOne: {
      width: 328,
      height: 104,
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 6,
      border: 'none',
      resize: 'none',
      paddingLeft: 6,
      paddingTop: 6,
      outline: 'none',
      marginBottom: -3,
      '@media (max-width: 520px)': {
        width: '100%',
      },
    },
    letterCount: {
      width: 322,
      textAlign: 'end',
      marginRight: 12,
      marginBottom: 43,
      '@media (max-width: 520px)': {
        width: '98%',
      },
    },
  }));

  const { builder } = QualityForm;
  const {
    values: { message },
  } = useFormikContext();
  const classes = useStyles();
  const [letterCount, setLettercount] = useState<LetterCount>({
    maxLetterCount: 140,
    currentLetterCount: 0,
  });

  useEffect(() => {
    setLettercount({
      ...letterCount,
      currentLetterCount: message.length,
    });
  }, [message]);

  return (
    <div className={classes.valoracionContainer}>
      <Typography variant="h4" className={classes.mainText}>
        Tu valoración:
      </Typography>
      <StarComponent score={score} />
      {formState === FormStateEnums.VALORACION_POSITIVA ? (
        <Typography variant="h5" className={classes.secondaryText}>
          ¡Muchas gracias! <br />
          ¿Qué fue lo que más te gustó?
        </Typography>
      ) : (
        <Typography variant="h5" className={classes.secondaryText}>
          Oh no...Lo sentimos.
          <br /> ¿Qué podríamos mejorar?
        </Typography>
      )}
      <Field className={classes.textAreaOne} as="textarea" name={builder[2].name} />
      <Typography
        variant="h6"
        className={classes.letterCount}
        style={{
          color: letterCount.currentLetterCount > letterCount.maxLetterCount ? 'red' : '#353535',
        }}
      >
        {letterCount.currentLetterCount}/{letterCount.maxLetterCount}
      </Typography>
    </div>
  );
};

export default ValoracionCliente;
