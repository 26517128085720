import { FormValuesInterface, Params } from '../types';

export const formatUserReview = (values: FormValuesInterface, params: Params) => {
  const userReview = {
    ...values,
    message: isEmpty(values.message),
    product: isEmpty(values.product),
    ...params,
  };
  return userReview;
};

export const isEmpty = (value: string) => {
  return value === '' ? '-' : value.trim();
};
