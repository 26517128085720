import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { FormStateEnums, ValidationSchemasEnums } from '../types/enums';
import { useEffect, useState } from 'react';
import TermsAndConditions from './generics/TermsAndConditions';
import TapHeader from './TapHeader';
import FormButton from './generics/FormButton';
import { AppGlobalState } from '../types';
import { loyaltyService } from '../services/loyalty.service';
import { formatUrlParams, submitForm, QualityForm } from '../helpers';
import FormikStepper from './formComponents/FormikStepper';

export const FormikForm = () => {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '22.5rem',
      backgroundColor: theme.palette.primary.light,
      margin: 'auto',
      '@media (max-width: 720px)': {
        width: '100%',
      },
    },
    form: {
      paddingTop: 20,
      minHeight: '552px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderTop: 'none',
      '@media (max-width: 720px)': {
        border: 'none',
      },
    },
  }));

  const { builder, onSubmit, validationSchema, initialValues } = QualityForm;

  const [AppGlobalState, setAppGlobalState] = useState<AppGlobalState>({
    search: window.location.search,
    params: {
      ...formatUrlParams(window.location.search),
    },
    companyName: '',
    currentValidationSchema: validationSchema[ValidationSchemasEnums.encuestasValidation],
    formState: FormStateEnums.LOADING,
  });

  useEffect(() => {
    const getCompanyData = async () => {
      const response = await loyaltyService.get_company(AppGlobalState.params.business_id);
      setAppGlobalState({
        ...AppGlobalState,
        companyName: response ? response.name : 'Nombre',
        formState: FormStateEnums.ENCUESTA_CLIENTES,
      });
    };
    if (!AppGlobalState.companyName && AppGlobalState.search) {
      getCompanyData();
    } else {
      setAppGlobalState({
        ...AppGlobalState,
        formState: FormStateEnums.ERROR,
      });
    }
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TapHeader appGlobalState={AppGlobalState} setAppGlobalState={setAppGlobalState} />
      <Formik
        onSubmit={async (values, actions) => {
          submitForm(
            values,
            actions,
            AppGlobalState,
            setAppGlobalState,
            validationSchema,
            onSubmit,
          );
        }}
        initialValues={initialValues}
        validationSchema={AppGlobalState.currentValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.form}>
            <FormikStepper
              builder={builder}
              setFieldValue={setFieldValue}
              values={values}
              AppGlobalState={AppGlobalState}
            />
            <FormButton formState={AppGlobalState.formState} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
