import { Button, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useFormikContext } from 'formik';
import { checkButtonDisableState } from '../../helpers/checkButtonDisableState';
import { appContainer, buttonStyles } from '../../styles/globalStyles';
import { FormValuesInterface } from '../../types';
import { ButtonTextEnums, FormStateEnums } from '../../types/enums';

interface FormButtonProps {
  formState: FormStateEnums;
}

const FormButton = ({ formState }: FormButtonProps) => {
  const useStyles = makeStyles((theme) => ({
    buttonContainer: {
      ...appContainer,
    },
    submitButton: {
      ...buttonStyles,
      backgroundColor: theme.palette.secondary.main,
      '& > span ': {
        color: theme.palette.primary.light,
      },
      '& > span > svg': {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.25),
        color: theme.palette.primary.light,
      },
      '&:hover': {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    disableButton: {
      ...buttonStyles,
      backgroundColor: '#C9C9C9',
      pointerEvents: 'none',
      '& > span ': {
        color: theme.palette.primary.light,
        paddingTop: theme.spacing(0.5),
      },
      '& > span > svg': {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.25),
      },
      '&:hover': {
        backgroundColor: '#C9C9C9',
      },
    },
  }));

  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext<FormValuesInterface>();

  const disabledButton = () => {
    if (isSubmitting) return true

    return  checkButtonDisableState(values, formState)
  }

  return (
    <div className={classes.buttonContainer}>
      {formState === FormStateEnums.SUCCESS ||
      formState === FormStateEnums.LOADING ||
      formState === FormStateEnums.MAPS ||
      formState === FormStateEnums.ERROR ? null : (
        <Button
          className={classes.submitButton}
          style={{
            backgroundColor: disabledButton() ? '#C9C9C9' : '#002350'
          }}
          type="submit"
          disabled={disabledButton()}
        >
          {formState === FormStateEnums.ENCUESTA_CLIENTES
            ? ButtonTextEnums.siguiente
            : ButtonTextEnums.enviar}
          {formState === FormStateEnums.ENCUESTA_CLIENTES ? <ArrowForwardIcon /> : null}
        </Button>
      )}
    </div>
  );
};

export default FormButton;
