import { makeStyles } from '@material-ui/core';
import TapDownload from '../generics/TapDownload';
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import Typography from '@material-ui/core/Typography';

interface SuccessScreenProps {
  company_name: string | undefined;
}

const SuccessScreen = ({ company_name }: SuccessScreenProps) => {
  const useStyles = makeStyles((theme) => ({
    upDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 140,
      '@media (max-width: 720px)': {
        height: 172,
      },
      '& > svg': {
        borderRadius: '50%',
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.primary.light,
        width: 48,
        height: 48,
        padding: 8,
        marginBottom: 12,
        marginTop: 7,
      },
      '& > h4': {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        lineHeight: 1.35,
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.upDiv}>
        <CheckTwoToneIcon />
        <Typography variant="h4">
          Enviaste tu valoración a<br /> {company_name}
        </Typography>
      </div>
      <TapDownload />
    </>
  );
};

export default SuccessScreen;
