import { makeStyles } from '@material-ui/core';
import cellPhone from '../../assets/Group 1714.png';
import DonwloadButtons from './DonwloadButtons';
import Typography from '@material-ui/core/Typography';

const TapDownload = () => {
  const useStyles = makeStyles((theme) => ({
    donwloadContainer: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    donwloadText: {
      color: theme.palette.secondary.main,
      paddingTop: 25,
      lineHeight: 1.35,
      marginBottom: 25,
      textAlign: 'center',
      marginTop: 0,
    },
    cellImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      '& img': {
        width: 254,
        imageRendering: 'crisp-edges',
        '@media (max-width: 720px)': {
          position: 'fixed',
          bottom: 0,
        },
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.donwloadContainer}>
      <Typography variant="h4" className={classes.donwloadText}>
        Descargá Tap y obtené increíbles
        <br /> descuentos en comercios
      </Typography>
      <DonwloadButtons />
      <div className={classes.cellImage}>
        <img src={cellPhone} alt="cell" />
      </div>
    </div>
  );
};

export default TapDownload;
