export const formatUrlParams = (values: string) => {
  const params = {
    business_id: values?.substring(1)?.split('&')[0]?.split('=')[1]
      ? values.substring(1).split('&')[0].split('=')[1]
      : '',
    client_id: values?.substring(1)?.split('&')[1]?.split('=')[1]
      ? values.substring(1).split('&')[1].split('=')[1]
      : '',
  };
  return params;
};
