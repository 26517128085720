import { Box, makeStyles, Typography } from '@material-ui/core';

const ErrorScreen = () => {
  const useStyles = makeStyles((theme) => ({
    errorText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.error.main,
      textAlign: 'center',
      height: 425,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.errorText}>
      <Typography variant="h4">
        Error <br /> Faltan Parametros
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
