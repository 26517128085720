import React, { useState } from 'react';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { StarArrayInterface } from '../../types/types';
import { starStyles } from '../../styles/globalStyles';

const starArray: StarArrayInterface[] = [
  { refPosition: 1 },
  { refPosition: 2 },
  { refPosition: 3 },
  { refPosition: 4 },
  { refPosition: 5 },
];

export interface StarComponentProps {
  setFieldValue?: (field: string, value: number, shouldValidate?: boolean) => void;
  score: number;
}

const StarComponent = ({ setFieldValue, score }: StarComponentProps) => {
  const [refScore, setRefScore] = useState<number>(0);

  useEffect(() => {
    setRefScore(score);
  }, [score]);

  const useStyles = makeStyles((theme) => ({
    starDiv: {
      marginBottom: 40,
      position: 'relative',
      right: 6.8,
    },
    blankStar: {
      ...starStyles,
      color: '#C9C9C9',
    },
    goldenStar: {
      ...starStyles,
      color: '#FFCE3D',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.starDiv}>
      {starArray.map((el: StarArrayInterface, index: number) => {
        if (el.refPosition <= refScore) {
          return (
            <StarRoundedIcon
              key={index}
              className={classes.goldenStar}
              onClick={setFieldValue ? () => setFieldValue('score', el.refPosition) : undefined}
            />
          );
        } else {
          return (
            <StarRoundedIcon
              key={index}
              className={classes.blankStar}
              onClick={setFieldValue ? () => setFieldValue('score', el.refPosition) : undefined}
            />
          );
        }
      })}
    </div>
  );
};

export default StarComponent;
