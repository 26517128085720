import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormValuesInterface } from '../../types';
import { makeStyles } from '@material-ui/core';

export interface MuiInputProps {
  values: FormValuesInterface;
  setFieldValue: (field: string, value: string | number, shouldValidate?: boolean) => void;
  input: any;
}

const MuiInput = ({ setFieldValue, values, input }: MuiInputProps) => {
  const useStyles = makeStyles((theme) => ({
    inputContainer: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'space-between',
      maxWidth: 651,
    },
    muiInput: {
      flex: 1,
      '& .MuiInputLabel-filled': {
        transform: 'translate(12px, 27px) scale(1)',
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 14px) scale(0.75)',
      },
      '& .MuiInputBase-root': {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: theme.spacing(2),
        background: '#F3F6FA',
        transition: 'none',
        color: '#353535',
        height: 56,
        borderRadius: theme.spacing(1),
        '&:before': {
          border: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Open Sans, sans-serif',
          color: theme.palette.secondary.main,
        },
      },
      '& .MuiFormLabel-root': {
        top: theme.spacing(-1),
        fontFamily: 'Open Sans, sans-serif',
        color: theme.palette.secondary.main,
        borderRadius: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  const [text, setText] = useState(values.product);

  const handleInput = (value: string) => {
    setText(value);
    setFieldValue('product', value);
  };

  return (
    <div className={classes.inputContainer}>
      <TextField
        name={input.name}
        value={text}
        variant="filled"
        className={classes.muiInput}
        onChange={(e) => handleInput(e.target.value)}
        label={input.placeholder}
        type="text"
        color="secondary"
      />
    </div>
  );
};

export default MuiInput;
