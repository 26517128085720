import { FormikHelpers } from 'formik';
import React from 'react';
import { checkFormNextStep } from '.';
import {
  AppGlobalState,
  FormStateEnums,
  FormValuesInterface,
  UserReview,
  ValidationSchemasEnums,
} from '../types';
import { formatUserReview } from './formatUserReview';

export const submitForm = async (
  values: FormValuesInterface,
  actions: FormikHelpers<FormValuesInterface>,
  AppGlobalState: AppGlobalState,
  setAppGlobalState: React.Dispatch<React.SetStateAction<AppGlobalState>>,
  validationSchema: any,
  onSubmit: (values: UserReview, actions: FormikHelpers<FormValuesInterface>) => void,
) => {
  switch (AppGlobalState.formState) {
    case FormStateEnums.ENCUESTA_CLIENTES:
      actions.setSubmitting(false);
      setAppGlobalState({
        ...AppGlobalState,
        formState: checkFormNextStep(values.score),
        currentValidationSchema: validationSchema[ValidationSchemasEnums.valoracionesValidation],
      });
      break;
    case FormStateEnums.VALORACION_NEGATIVA:
    case FormStateEnums.VALORACION_POSITIVA: {
      actions.setSubmitting(false);
      await onSubmit(formatUserReview(values, AppGlobalState.params), actions);
      setAppGlobalState({
        ...AppGlobalState,
        formState: FormStateEnums.SUCCESS,
      });
      break;
    }
    default:
      setAppGlobalState({
        ...AppGlobalState,
        currentValidationSchema: validationSchema[ValidationSchemasEnums.encuestasValidation],
        formState: FormStateEnums.ENCUESTA_CLIENTES,
      });
  }
};
