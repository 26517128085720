import { makeStyles } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import TapLogo from './svg/TapLogo';
import { AppGlobalState, FormStateEnums } from '../types';
import React from 'react';

interface TapHeaderProps {
  appGlobalState: AppGlobalState;
  setAppGlobalState: React.Dispatch<React.SetStateAction<AppGlobalState>>;
}

const TapHeader = ({ appGlobalState, setAppGlobalState }: TapHeaderProps) => {
  const useStyles = makeStyles((theme) => ({
    header: {
      height: 56,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme.palette.secondary.main}`,
      '@media (max-width: 720px)': {
        border: 'none',
      },
      '& > svg': {
        width: 66,
        height: 22,
      },
    },
    arrowIcon: {
      position: 'absolute',
      cursor: 'pointer',
      left: '40%',
      '@media (max-width: 1280px)': {
        left: '35%',
      },
      '@media (max-width: 1024px)': {
        left: '31.5%',
      },
      '@media (max-width: 870px)': {
        left: '27.5%',
      },
      '@media (max-width: 720px)': {
        left: '0%',
      },
    },
  }));

  const classes = useStyles();

  const getCondition = () => {
    if (
      appGlobalState.formState === FormStateEnums.VALORACION_POSITIVA ||
      appGlobalState.formState === FormStateEnums.VALORACION_NEGATIVA
    ) {
      return true;
    }
  };

  return (
    <div className={classes.header}>
      {getCondition() && (
        <ArrowBackRoundedIcon
          className={classes.arrowIcon}
          onClick={() => {
            setAppGlobalState({
              ...appGlobalState,
              formState: FormStateEnums.ENCUESTA_CLIENTES,
            });
          }}
        />
      )}
      <TapLogo />
    </div>
  );
};

export default TapHeader;
