import * as Yup from 'yup';
import StarComponent from '../components/generics/StarComponent';
import ValoracionCliente from '../components/formComponents/ValoracionCliente';
import { InputDisplayState } from '../types/enums';
import { FormValuesInterface, QualityFormInterface, UserReview } from '../types';
import { FormikHelpers } from 'formik';
import { loyaltyService } from '../services/loyalty.service';
import MuiInput from '../components/generics/MuiInput';

export const QualityOptions = [1, 2, 3, 4, 5];

export const QualityEncuentasValidationSchema = Yup.object({
  product: Yup.string(),
  score: Yup.number().oneOf(QualityOptions).required().positive(),
});

export const QualityValoracionValidationSchema = Yup.object().shape({
  message: Yup.string().max(140),
});

/**
 * @params initialValues - Form initial values
 * @params builder - FormBuilderInterface
 * @params validationSchema - Yup schema validation
 * @params onSubmit - function trigger when form submits
 */
export const QualityForm: QualityFormInterface = {
  initialValues: {
    product: '',
    score: 0,
    message: '',
  },
  builder: [
    {
      displayid: InputDisplayState.ENCUESTA,
      component: MuiInput,
      name: 'product',
      optional: true,
      needValue: true,
      placeholder: '¿Qué producto compraste?',
    },
    {
      displayid: InputDisplayState.ENCUESTA,
      component: StarComponent,
      name: 'score',
      needValue: true,
      headerText: 'Del 1 al 5 ¿Cómo fue tu experiencia en ',
    },
    {
      displayid: InputDisplayState.VALORACION,
      component: ValoracionCliente,
      name: 'message',
    },
  ],
  validationSchema: {
    encuestasValidation: QualityEncuentasValidationSchema,
    valoracionValidation: QualityValoracionValidationSchema,
  },
  onSubmit: async (values: UserReview, actions: FormikHelpers<FormValuesInterface>) => {
    try {
      const serverResponse = await loyaltyService.post_user_review(values);
      return serverResponse;
    } catch (err) {
      return err;
    }
  },
};
