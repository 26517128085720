import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';

import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import { FormikForm } from './components/FormikForm';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <FormikForm />
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => console.log('onSuccess'),
  onUpdate: ({ waiting }) => {
    if (waiting) {
      waiting.postMessage({ type: 'SKIP_WAITING' });

      waiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  },
});
