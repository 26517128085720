import { FormStateEnums, FormValuesInterface } from '../types';

export const checkButtonDisableState = (values: FormValuesInterface, formState: FormStateEnums) => {
  if (values.score !== 0 && formState === FormStateEnums.ENCUESTA_CLIENTES) {
    return false;
  }
  if (values.message.length <= 140 && formState !== FormStateEnums.ENCUESTA_CLIENTES) {
    return false;
  }

  return true
};
