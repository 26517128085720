import { makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { appContainer } from '../../styles/globalStyles';
import { InputDisplayState } from '../../types/enums';
import { FormBuilderInterface, FormValuesInterface } from '../../types/types';

interface EncuentasClientProps {
  builder: FormBuilderInterface[];
  values: FormValuesInterface;
  setFieldValue: (field: string, value: string | number, shouldValidate?: boolean) => void;
  companyName: string | undefined;
}

const EncuestasCliente = ({
  builder,
  values,
  setFieldValue,
  companyName,
}: EncuentasClientProps) => {
  const useStyles = makeStyles((theme) => ({
    encuestasContainer: {
      ...appContainer,
      marginTop: 28,
    },
    mainText: {
      color: theme.palette.secondary.main,
      fontSize: 18,
      lineHeight: 1.4,
      width: '100%',
      margin: 0,
      marginBottom: 24,
    },
    form: {
      backgroundColor: theme.palette.primary.light,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 32,
    },
    formikInput: {
      outline: 'none',
      marginBottom: 32,
      '& > input': {
        backgroundColor: theme.palette.primary.dark,
        height: 56,
        width: '100%',
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.secondary.main,
        borderRadius: 6,
        borderColor: 'transparent',
        paddingLeft: 16,
      },
      '& > input::placeholder': {
        color: theme.palette.secondary.main,
        fontFamily: 'Open Sans',
      },
    },
    company: {
      color: '#FFCE3D'
    },
    inputText: {
      fontSize: 16,
      marginBottom: 22,
      color: theme.palette.secondary.main,
      width: '100%',
      fontWeight: 400,
      fontFamily: 'Open Sans',
    },
    submitButton: {
      height: 44,
      width: 183,
      borderRadius: 16,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.encuestasContainer}>
      <Typography variant="h4" className={classes.mainText}>
        ¡Hola! Queremos conocer tu experiencia en <span className={classes.company}>{companyName}</span>
      </Typography>
      {builder.map((el: FormBuilderInterface, index: number) => {
        if (el.displayid === InputDisplayState.ENCUESTA) {
          return (
            <div key={index}>
              {el.headerText ? (
                <Typography variant="h5" className={classes.inputText}>
                  {`${el.headerText}`} <span className={classes.company}>{companyName}</span>?
                </Typography>
              ) : null}
              <div className={classes.formikInput}>
                <Field
                  component={el.component}
                  input={el}
                  name={el.name}
                  values={values}
                  placeholder={el.placeholder}
                  setFieldValue={el.needValue ? setFieldValue : null}
                  score={el.needValue ? values.score : null}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default EncuestasCliente;
