import { makeStyles } from '@material-ui/core';
import AppStore from '../svg/appStore';
import GooglePlay from '../svg/googlePlay';

const DonwloadButtons = () => {
  const useStyles = makeStyles((theme) => ({
    donwloadButtonDiv: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 30,
      justifyContent: 'center',
      '& > a:nth-child(1)': {
        marginRight: 13,
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.donwloadButtonDiv}>
      <GooglePlay />
      <AppStore />
    </div>
  );
};

export default DonwloadButtons;
