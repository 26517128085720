export const appContainer = {
  paddingLeft: 16,
  paddingRight: 16,
};

export const buttonStyles = {
  fontFamily: 'Comfortaa',
  fontSize: 14,
  height: 44,
  width: 183,
  borderRadius: 16,
};

export const starStyles = {
  height: 47,
  width: 47,
  marginRight: 1,
};
